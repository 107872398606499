
import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ChildrenPage = ({ data }) => {
  return (
    <Layout pageTitle="Dla dzieci">
      <p className="textBlock">
        Dobra Marka jako szwalnia szyje w bardzo dużych ilościach, na zlecenie różnorodne produkty dla dzieci. Jednocześnie pod swoją marką  oferuje szeroki wybór akcesoriów dla maluszków. Można skompletować u nas całą wyprawkę dla dziecka włącznie z wyposażeniem pokoiku. W ofercie posiadamy m.in.: kokony niemowlęce, poduszki, kołderki, otulacze, rożki, poduchy do karmienia  i wiele innych.
      </p>
      <p className="textBlock">
        Nasze produkty są wykonane bardzo staranie oraz  uroczo dzięki czemu doskonale prezentują się na zdjęciach. Wykonane są głównie z polskich materiałów najwyższej jakości, przede wszystkim  z bawełny, która posiada certyfikat bezpieczeństwa Oeko-Tex Standard 100.  Jeśli nie ma w naszej ofercie produktu, który chcieli by Państwo zamówić zapraszamy do kontaktu.
      </p>

      <div className="md:masonry before:box-inherit after:box-inherit p-20 -m-20">
        {
          data.allMdx.nodes.map(node => (
                 <article key={node.id} className="break-inside rounded-lg hover:bg-secendary hover:bg-opacity-10 bg-white transition duration-200 mb-10">
              {
                 <Link to={`/children/${node.slug}`} className="hover:cursor-pointer">

<GatsbyImage
          className="rounded-t-lg mt-10 md:mt-0" 
          image={getImage(node.frontmatter.hero_image)}
          alt={node.frontmatter.hero_image_alt}
        />
                  <div className="py-4 px-8">
                    <div className="text-gray-900 font-bold  tracking-tight">
                      {node.frontmatter.title}
                  </div>
                    <p className="hover:cursor-pointer py-3 text-gray-600 leading-6">
                      {node.frontmatter.description}
                    </p>
                  </div>
                </Link>
              }
            </article>
          ))
        }

      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(filter: {frontmatter: {category: {eq: "children"}}}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          category
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        slug

      }
    }
  }
`

export default ChildrenPage